export const blockchainMainItem = [
    { id: 1, title: "NFTs" },
    { id: 2, title: "Ethereum Virtual Machine (EVM)" },
    { id: 3, title: "Blockchain" },
    { id: 4, title: "Metaverse" },
    { id: 5, title: "Web 3.0" },
    { id: 6, title: "Crypto" },
    { id: 7, title: "DeFi" },
    { id: 8, title: "ICO/STO/IDO" },
    { id: 9, title: "Marketing" },
];

export const EVM1 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/EVM/Token-Bound-Account-Development.svg",
        href: "/evm/token-bound-account-development",
        title: "Token Bound Account Development",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/EVM/Account-Abstraction-ERC-4337-Development-Services.svg",
        href: "/evm/account-abstraction-erc-4337-development",
        title: "Account Abstraction ERC 4337 Development",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/EVM/EIP-7702-dApp-Development-Services.svg",
        href: "/evm/eip-7702-dapp-development",
        title: "EIP-7702 dApp Development Services",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/EVM/ERC-6551-dApp-Development-Services.svg",
        href: "/evm/erc-6551-dapp-development-services",
        title: "ERC-6551 dApp Development Services",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/EVM/ERC-4337-dApp-Development-Services.svg",
        href: "/evm/erc-4337-dapp-development-services",
        title: "ERC-4337 dApp Development Services",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/EVM/Zero-Knowledge-Proof-Based-dApp-Development-Services.svg",
        href: "/evm/zero-knowledge-proof-based-dapp-development-services",
        title: "Zero Knowledge Proof Based dApp Development Services",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/EVM/Post-Quantum-Ethereum-Based-dApp-Development-Services.svg",
        href: "/evm/post-quantum-ethereum-based-dapp-development-services",
        title: "Post Quantum Ethereum-Based dApp Development Services",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/EVM/SLH-Dsa-Based-Post-Quantum-Secure-Blockchain-Development-Services.svg",
        href: "/evm/slh-dsa-based-post-quantum-secure-blockchain-development-services",
        title: "SLH DSA (Sphincs+) Based Post-Quantum Blockchain Development Services",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/EVM/Paymaster-Smart-Contract-Development-Services.svg",
        href: "/evm/paymaster-smart-contract-development-services",
        title: "Paymaster Smart Contract Development Services",
    },
];

export const NFT1 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/NFT/NFT Development Services.svg",
        href: "/nfts/development",
        title: "NFT Development Services",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/NFT/White-Label NFT Marketplace.svg",
        href: "/nfts/white-label-marketplace-development",
        title: "White-Label NFT Marketplace",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace Development.svg",
        href: "/nfts/marketplace-development ",
        title: "NFT Marketplace Development",
    },
    /*  {
        id: 4,
        image: "/assets/Header/Blockchain/NFT/Free NFT Consulting.svg",
        href: "/nfts/free-consulting",
        title: "Free NFT Consulting",
    },*/
    {
        id: 4,
        image: "/assets/Header/Blockchain/NFT/NFT Ecosystem.svg",
        href: "/nfts/ecosystem",
        title: "NFT Ecosystem",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/NFT/Whitelabel Multi chain NFT.svg",
        href: "/nfts/white-label-multi-chain-marketplace-development",
        title: "Whitelabel Multi chain NFT",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/NFT/White Label NFT Launchpad.svg",
        href: "/nfts/white-label-launchpad-development",
        title: "White Label NFT Launchpad",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/NFT/NFT Launchpad Development.svg",
        href: "/nfts/launchpad-development",
        title: "NFT Launchpad Development",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/NFT/Cross-Chain NFT Marketplace.svg",
        href: "/nfts/cross-chain-marketplace-development",
        title: "Cross-Chain NFT Marketplace",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/NFT/NFT Exchange Development.svg",
        href: "/nfts/exchange-development",
        title: "NFT Exchange Development",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/NFT/NFT Wallet Development.svg",
        href: "/nfts/wallet-development",
        title: "NFT Wallet Development",
    },
    {
        id: 11,
        image: "/assets/Header/Blockchain/NFT/NFT minting platform development.svg",
        href: "/nfts/minting-platform-development",
        title: "NFT Minting Platform Development",
    },
    {
        id: 12,
        image: "/assets/Header/Blockchain/NFT/NFT Token Development.svg",
        href: "/nfts/non-fungible-token-development",
        title: "NFT Token Development",
    },
    {
        id: 13,
        image: "/assets/Header/Blockchain/NFT/DAO Enabled NFT Platform.svg",
        href: "/nfts/dao-enabled-platform-development",
        title: "DAO Enabled NFT Platform",
    },
    {
        id: 14,
        image: "/assets/Header/Blockchain/NFT/NFT marketplace with Governance.svg",
        href: "/nfts/marketplace-with-governance-token-development",
        title: "NFT marketplace with Governance",
    },
    {
        id: 15,
        image: "/assets/Header/Blockchain/NFT/Celebrity NFT Marketplace.svg",
        href: "/nfts/celebrity-nft-marketplace-development",
        title: "Celebrity NFT Marketplace",
    },
    {
        id: 16,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace for Music.svg",
        href: "/nfts/music-nft-marketplace-development",
        title: "NFT Marketplace For Music",
    },
    {
        id: 17,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace for Art.svg",
        href: "/nfts/nft-art-marketplace-development",
        title: "NFT Marketplace For Artist",
    },
    {
        id: 18,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace for Sports.svg",
        href: "/nfts/sports-nft-marketplace-development",
        title: "NFT Marketplace For Sports",
    },
    {
        id: 19,
        image: "/assets/Header/Blockchain/NFT/NFT for Physical assets.svg",
        href: "/nfts/physical-assets-marketplace-development",
        title: "NFT For Physical Assets",
    },
    {
        id: 20,
        image: "/assets/Header/Blockchain/NFT/NFT Ticketing Marketplace.svg",
        href: "/nfts/ticketing-marketplace-development",
        title: "NFT Ticketing Marketplace",
    },
];

export const NFT2 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/NFT/SFT Development.svg",
        href: "/nfts/sft-development",
        title: "SFT Development",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/NFT/NFT Studio.svg",
        href: "/nfts/nft-studio",
        title: "NFT Studio",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/NFT/NFT Aggregator Marketplace.svg",
        href: "/nfts/aggregator-marketplace-development",
        title: "NFT Aggregator Marketplace",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Ethereum.svg",
        href: "/nfts/ethereum-nft-marketplace-development",
        title: "NFT Marketplace On Ethereum",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Polygon.svg",
        href: "/nfts/polygon-nft-marketplace-development",
        title: "NFT Marketplace on Polygon",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace on BSC.svg",
        href: "/nfts/bsc-nft-marketplace-development",
        title: "NFT Marketplace on BSC",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Avalanche.svg",
        href: "/nfts/avalanche-nft-marketplace-development",
        title: "NFT Marketplace on Avalanche",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/NFT/NFT Development on Sidechain.svg",
        href: "/nfts/sidechain-nft-marketplace-development",
        title: "NFT Development on Sidechain",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Solana.svg",
        href: "/nfts/solana-nft-marketplace-development",
        title: "NFT Marketplace on Solana",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace on Harmony.svg",
        href: "/nfts/harmony-nft-marketplace-development",
        title: "NFT Marketplace on Harmony",
    },
    {
        id: 11,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace like SuperRare.svg",
        href: "/nfts/superrare-like-nft-marketplace-development ",
        title: "NFT Marketplace Like SuperRare",
    },
    {
        id: 12,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace like Sudoswap.svg",
        href: "/nfts/sudoswap-like-nft-marketplace-development",
        title: "NFT Marketplace Like Sudoswap",
    },
    {
        id: 13,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace like Sorare.svg",
        href: "/nfts/sorare-like-nft-marketplace-development",
        title: "NFT Marketplace Like Sorare",
    },
    {
        id: 14,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace like OpenSea.svg",
        href: "/nfts/opensea-like-nft-marketplace-development",
        title: "NFT Marketplace Like OpenSea",
    },
    {
        id: 15,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace like Solsea.svg",
        href: "/nfts/solsea-like-nft-marketplace-development",
        title: "NFT Marketplace Like Solsea",
    },
    {
        id: 16,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace like Rarible.svg",
        href: "/nfts/rarible-like-nft-marketplace-development",
        title: "NFT Marketplace Like Rarible",
    },
    {
        id: 17,
        image: "/assets/Header/Blockchain/NFT/NFT Layer 2 Development.svg",
        href: "/nfts/layer-2-development",
        title: "NFT Layer 2 Development",
    },
    {
        id: 18,
        image: "/assets/Header/Blockchain/NFT/Phygital NFT Marketplace.svg",
        href: "/nfts/phygital-marketplace-development",
        title: "Phygital NFT Marketplace",
    },
    {
        id: 19,
        image: "/assets/Header/Blockchain/NFT/P2E NFT Game Development.svg",
        href: "/nfts/p2e-game-development",
        title: "P2E NFT Game Development",
    },
    {
        id: 20,
        image: "/assets/Header/Blockchain/NFT/NFT Marketplace for Real estate.svg",
        href: "/nfts/nft-real-estate-marketplace-development",
        title: "NFT Marketplace For Real estate",
    },
];

export const NFT3 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/NFT/NFT in E-commerce.svg",
        href: "/nfts/nft-in-ecommerce",
        title: "NFT in E-commerce",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/NFT/NFT Gaming Platform.svg",
        href: "/nfts/gaming-platform-development",
        title: "NFT Gaming Platform",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/NFT/DAO Enabled NFT Platform.svg",
        href: "/nfts/dungeon-swap-like-nft-game-development",
        title: "Dungeon Swap Like NFT Game",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/NFT/My Crypto Heros Like NFT Game.svg",
        href: "/nfts/my-crypto-heroes-like-nft-game-development",
        title: "My Crypto Heros Like NFT Game",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/NFT/Zed Run Like NFT Game.svg",
        href: "/nfts/zed-run-like-nft-game-development",
        title: "Zed Run Like NFT Game",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/NFT/Decentraland Like NFT Game.svg",
        href: "/nfts/decentraland-like-nft-game-development",
        title: "Decentraland Like NFT Game",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/NFT/NFT Racing Game.svg",
        href: "/nfts/nft-racing-game-development",
        title: "NFT Racing Game",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/NFT/Revv Racing Like NFT Game.svg",
        href: "/nfts/revv-racing-like-nft-game-development",
        title: "Revv Racing Like NFT Game",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/NFT/NFT Casino Game.svg",
        href: "/nfts/casino-game-development",
        title: "NFT Casino Game",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/NFT/Social Token Development.svg",
        href: "/nfts/nft-social-token-development",
        title: "NFT Social Token Development",
    },

    {
        id: 11,
        image: "/assets/Header/Blockchain/NFT/NFT Staking platform.svg",
        href: "/nfts/staking-platform-development",
        title: "NFT Staking platform",
    },
    {
        id: 12,
        image: "/assets/Header/Blockchain/NFT/NFT Streaming Platform.svg",
        href: "/nfts/streaming-platform-development",
        title: "NFT Streaming Platform",
    },
    {
        id: 13,
        image: "/assets/Header/Blockchain/NFT/NFT Consulting Service.svg",
        href: "/nfts/consulting-service",
        title: "NFT Consulting Service",
    },
    {
        id: 14,
        image: "/assets/Header/Blockchain/NFT/NFT Listing Services.svg",
        href: "/nfts/listing-services",
        title: "NFT Listing Services",
    },
    {
        id: 15,
        image: "/assets/Header/Blockchain/NFT/Fractional NFT Marketplace.svg",
        href: "/nfts/fractional-marketplace-development ",
        title: "Fractional NFT Marketplace",
    },
    {
        id: 16,
        image: "/assets/Header/Blockchain/NFT/Shards NFT Platform.svg",
        href: "/nfts/shards-nft-platform-development",
        title: "Shards NFT Platform",
    },
    {
        id: 17,
        image: "/assets/Header/Blockchain/NFT/White Label P2E NFT Games.svg",
        href: "/nfts/white-label-p2e-game-development",
        title: "White Label P2E NFT Games",
    },
    {
        id: 18,
        image: "/assets/Header/Blockchain/NFT/Web2 to Web3 Game.svg",
        href: "/nfts/web2-to-web3-game-development",
        title: "Web2 To Web3 Game",
    },
    {
        id: 19,
        image: "/assets/Header/Blockchain/NFT/NFT Game Marketplace.svg",
        href: "/nfts/gaming-marketplace-development",
        title: "NFT Game Marketplace",
    },
    {
        id: 20,
        image: "/assets/Header/Blockchain/NFT/Move To Earn NFT Platform.svg",
        href: "/nfts/move-to-earn-platform-development",
        title: "Move To Earn NFT Platform",
    },
];

export const marketing1 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/Marketing/NFT Marketing Services.svg",
        href: "/marketing/nft",
        title: "NFT Marketing Services",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/Marketing/NFT Marketplace Marketing Services.svg",
        href: "/marketing/nft-marketplace",
        title: "NFT Marketplace Marketing Services",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/Marketing/NFT Collectibles Marketing.svg",
        href: "/marketing/nft-collectibles",
        title: "NFT Collectibles Marketing",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/Marketing/NFT Game Marketing.svg",
        href: "/marketing/nft-game",
        title: "NFT Game Marketing",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/Marketing/NFT Telegram Marketing.svg",
        href: "/marketing/nft-telegram",
        title: "NFT Telegram Marketing",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/Marketing/Influencer Marketing Agency.svg",
        href: "/marketing/influencer",
        title: "Influencer Marketing Agency",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/Marketing/NFT PRMedia Marketing.svg",
        href: "/marketing/nft-pr-and-media",
        title: "NFT PR and Media Marketing",
    },

    {
        id: 8,
        image: "/assets/Header/Blockchain/Marketing/Discord Marketing.svg",
        href: "/marketing/discord",
        title: "Discord Marketing",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/Marketing/Discord Server Marketing.svg",
        href: "/marketing/discord-server-marketing",
        title: "Discord Server Marketing",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/Marketing/Discord Brand Marketing.svg",
        href: "/marketing/discord-brand",
        title: "Discord Brand Marketing",
    },
    {
        id: 11,
        image: "/assets/Header/Blockchain/Marketing/Discord Game Marketing.svg",
        href: "/marketing/discord-game",
        title: "Discord Game Marketing",
    },
];

// export const marketing2 = [
//     {
//         id: 11,
//         image: "/assets/Header/Blockchain/Marketing/Discord Game Marketing.svg",
//         href: "/marketing/discord-game",
//         title: "Discord Game Marketing",
//     },
// ];

export const metaverse1 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse Avatar Developments.svg",
        href: "/metaverse/metaverse-development-company",
        title: "Metaverse Development Services",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse NFT Marketplace Development.svg",
        href: "/metaverse/metaverse-nft-marketplace-development",
        title: "Metaverse NFT Marketplace Development",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse Healthcare Solutions.svg",
        href: "/metaverse/healthcare-solutions-development",
        title: "Metaverse Healthcare Solutions",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/Metaverse/NFT Metaverse Launchpad Development.svg",
        href: "/metaverse/nft-launchpad-development",
        title: "NFT Metaverse Launchpad Development",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse Event Platform.svg",
        href: "/metaverse/event-platform-development",
        title: "Metaverse Event Platform",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse Platform for Social Interaction.svg",
        href: "/metaverse/social-interaction-platform-development",
        title: "Metaverse Platform for Social Interaction",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse NFT Game Development.svg",
        href: "/metaverse/nft-game-development",
        title: "Metaverse NFT Game Development",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse Art Gallery.svg",
        href: "/metaverse/art-gallery-development",
        title: "Metaverse Art Gallery",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse Avatar Development.svg",
        href: "/metaverse/avatar-development",
        title: "Metaverse Avatar Development",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse 3D Space Development.svg",
        href: "/metaverse/3d-space-development",
        title: "Metaverse 3D Space Development",
    },
    {
        id: 11,
        image: "/assets/Header/Blockchain/Metaverse/Metaverse Virtual Working Space.svg",
        href: "/metaverse/virtual-working-space-development",
        title: "Metaverse Virtual Working Space",
    },
];

// export const metaverse2 = [
//     {
//         id: 1,
//         image: "/assets/Header/Blockchain/Metaverse/Metaverse NFT Game Development.svg",
//         href: "/metaverse/nft-game-development",
//         title: "Metaverse NFT Game Development",
//     },
//     {
//         id: 2,
//         image: "/assets/Header/Blockchain/Metaverse/Metaverse Art Gallery.svg",
//         href: "/metaverse/art-gallery-development",
//         title: "Metaverse Art Gallery",
//     },
//     {
//         id: 3,
//         image: "/assets/Header/Blockchain/Metaverse/Metaverse Avatar Development.svg",
//         href: "/metaverse/avatar-development",
//         title: "Metaverse Avatar Development",
//     },
//     {
//         id: 4,
//         image: "/assets/Header/Blockchain/Metaverse/Metaverse 3D Space Development.svg",
//         href: "/metaverse/3d-space-development",
//         title: "Metaverse 3D Space Development",
//     },
//     {
//         id: 5,
//         image: "/assets/Header/Blockchain/Metaverse/Metaverse Virtual Working Space.svg",
//         href: "/metaverse/virtual-working-space-development",
//         title: "Metaverse Virtual Working Space",
//     },
// ];

// export const metaverse3 = [
//     {
//         id: 1,
//         image: "/assets/Header/Blockchain/Metaverse/Metaverse Event Platform.svg",
//         href: "/metaverse/event-platform-development",
//         title: "Metaverse Event Platform",
//     },
//     {
//         id: 2,
//         image: "/assets/Header/Blockchain/Metaverse/Metaverse Platform for Social Interaction.svg",
//         href: "/metaverse/social-interaction-platform-development",
//         title: "Metaverse Platform for Social Interaction",
//     },
//     {
//         id: 3,
//         image: "/assets/Header/Blockchain/Metaverse/Metaverse Virtual Working Space.svg",
//         href: "/metaverse/virtual-working-space-development",
//         title: "Metaverse Virtual Working Space",
//     },
// ];

export const web3_1 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 Development.svg",
        href: "/web3/web3-development-services",
        title: "Web 3.0 Development",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 dApp Development.svg",
        href: "/web3/dapps-development-services",
        title: "Web3 dApp Development",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 Wallet Development.svg",
        href: "/web3/wallet-development",
        title: "Web3 Wallet Development",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 Game Development.svg",
        href: "/web3/game-development",
        title: "Web 3.0 Game Development",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 Marketing Services.svg",
        href: "/web3/marketing-services",
        title: "Web 3.0 Marketing Services",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/Web 3.0/Metamask like Wallet Development.svg",
        href: "/web3/metamask-like-wallet-development",
        title: "Web3 Metamask like Wallet Development",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/Web 3.0/Decentralized-Multichain-Wallet-like-Backpack-Evm-Solana.svg",
        href: "/web3/decentralized-multichain-wallet-like-backpack-evm-solana",
        title: "Decentralized Multichain Wallet like Backpack Evm Solana",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/Web 3.0/Meme-Coin-Development.svg",
        href: "/web3/meme-coin-development",
        title: "Meme Coin Development",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/Web 3.0/AI-ML-Based-Glasses-App-Development.svg",
        href: "/web3/ai-ml-based-glasses-app-development",
        title: "AI/ML Based Glasses App Development",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/Web 3.0/Multi-Signature-Wallet-Development-Services.svg",
        href: "/web3/multi-signature-wallet-development-services",
        title: "Multi Signature Wallet Development Services",
    },
    {
        id: 11,
        image: "/assets/Header/Blockchain/Web 3.0/Farcaster-Based-dApps-Development-Services.svg",
        href: "/web3/farcaster-based-dapps-development",
        title: "Farcaster-Based dApps Development Services",
    },
    {
        id: 12,
        image: "/assets/Header/Blockchain/Web 3.0/Cello-Blockchain-Based-dApps-Development-Services.svg",
        href: "/web3/cello-blockchain-based-dapps-development",
        title: "Cello Blockchain-Based dApps Development Services",
    },
];

export const web3_2 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/Web 3.0/Bitcoin-Layer-2-Smart-Contract-Development-Services.svg",
        href: "/web3/bitcoin-layer2-smart-contract-development-services",
        title: "Bitcoin Layer 2 Smart Contract Development Services",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/Web 3.0/Decentralized-Video-Conference-App-Development-with-Solidity.svg",
        href: "/web3/decentralized-video-conference-app-solidity-blockchain",
        title: "Decentralized Video Conference App Development with Solidity",
    },
];

// export const web3_3 = [
//   {
//     id: 1,
//     image: "/assets/Header/Blockchain/Web 3.0/Web 3.0 Development.svg",
//     href: "",
//     title: "Web 3.0 Development",
//   },
// ];

export const defi1 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/DeFi/Decentralized Finance (DeFi) Development.svg",
        href: "/defi/defi-development-services",
        title: "DeFi Development Company",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/DeFi/DeFi Platform like Pancakeswap.svg",
        href: "/defi/pancakeswap-like-platform-development",
        title: "DeFi Platform like Pancakeswap",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/DeFi/DAO Services.svg",
        href: "/defi/dao-development",
        title: "DeFi DAO Services",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/DeFi/Whitelabel Swap Exchange Development.svg",
        href: "/defi/white-label-swap-exchange-development",
        title: "Whitelabel Swap Exchange Development",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/DeFi/Pinksale Like Launchpad Development.svg",
        href: "/defi/pinksale-like-launchpad-development",
        title: "Pinksale Like Launchpad Development",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/DeFi/DeFi Staking Platform Development.svg",
        href: "/defi/staking-platform-development",
        title: "DeFi Staking Platform Development",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/DeFi/DeFi Yield Farming Development.svg",
        href: "/defi/yield-farming-development",
        title: "DeFi Yield Farming Development",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/DeFi/DeFi Marketing Services.svg",
        href: "/defi/marketing-services",
        title: "DeFi Marketing Services",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/DeFi/DeFi Application Development Services.svg",
        href: "/defi/application-development",
        title: "DeFi Application Development Services",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/DeFi/DeFi Token Development Services.svg",
        href: "/defi/token-development",
        title: "DeFi Token Development Services",
    },
    {
        id: 11,
        image: "/assets/Header/Blockchain/DeFi/DeFi Protocol Development like yearn finance.svg",
        href: "/defi/protocol-like-yearn-finance-development",
        title: "DeFi Protocol Development like yearn finance",
    },
    {
        id: 12,
        image: "/assets/Header/Blockchain/DeFi/Limit-Order-Automated-Market-Maker-Trading-Platform-Development-Services.svg",
        href: "/defi/limit-order-automated-market-maker-trading-platform-development-services",
        title: "Limit Order Automated Market Maker Development Services",
    },
];

export const defi2 = [
    {
        id: 14,
        image: "/assets/Header/Blockchain/DeFi/Multichain-DEX-Development-Services.svg",
        href: "/defi/multichain-dex-development-services",
        title: "Multichain DEX Development Services",
    },

    {
        id: 2,
        image: "/assets/Header/Blockchain/DeFi/DeFi Wallet Development Solution.svg",
        href: "/defi/wallet-development",
        title: "DeFi Wallet Development Solution",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/DeFi/DeFi Synthetic Assets Development.svg",
        href: "/defi/synthetic-assets-development",
        title: "DeFi Synthetic Assets Development",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/DeFi/DeFi crowdfunding platform.svg",
        href: "/defi/crowd-funding-platform-development",
        title: "DeFi Crowdfunding Platform",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/DeFi/DeFi Real estate platform.svg",
        href: "/defi/real-estate-platform-development",
        title: "DeFi Real-Estate Platform",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/DeFi/DeFi Exchange Development Solution.svg",
        href: "/defi/dex-development",
        title: "DeFi Exchange Development Solution",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/DeFi/DeFi Platform Like Uniswap.svg",
        href: "/defi/uniswap-like-platform-development",
        title: "DeFi Platform Like Uniswap",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/DeFi/telegram-trading-bot-development.svg",
        href: "/defi/telegram-trading-bot-development",
        title: "Telegram Trading Bot Development",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/DeFi/smart-wallet-account-development-services.svg",
        href: "/defi/smart-wallet-account-development-services",
        title: "Smart Wallet Account Development Services",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/DeFi/analytics-dashboard-development-services.svg",
        href: "/defi/analytics-dashboard-development-services",
        title: "Analytics Dashboard Development Services",
    },
    {
        id: 11,
        image: "/assets/Header/Blockchain/DeFi/On-Chain-Trading-App-Development-Services.svg",
        href: "/defi/on-chain-trading-app-development-services",
        title: "On-Chain Trading App Development Services",
    },
    {
        id: 12,
        image: "/assets/Header/Blockchain/DeFi/Real-World-Asset-Tokenization-dApp-Development.svg",
        href: "/defi/real-world-asset-tokenization-platform-development",
        title: "Real World Asset Tokenization dApp Development Services",
    },
];

export const defi3 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/DeFi/Lending-and-Borrowing-Protocol-Development.svg",
        href: "/defi/lending-and-borrowing-protocol-development",
        title: "Lending and Borrowing Protocol Development",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/DeFi/Cross-Chain-Swap-Protocol-Development.svg",
        href: "/defi/cross-chain-swap-protocol-development",
        title: "Cross Chain Swap Protocol Development Services",
    },
];

export const ico1 = [
    {
        id: 0,
        title: "1. ICO",
        textClr: "text-[#410ead]",
        // href: "/",
    },
    {
        id: 1,
        image: "/assets/Header/Blockchain/ICO STO IDO/ICO Development.svg",
        href: "/ico/ico-development",
        title: "ICO Development",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/ICO STO IDO/ICO Marketing.svg",
        href: "/ico/marketing",
        title: "ICO Marketing",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/ICO STO IDO/ICO Community Marketing.svg",
        href: "/ico/community-marketing",
        title: "ICO Community Marketing",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/ICO STO IDO/ICO Telegram Marketing.svg",
        href: "/ico/telegram-marketing",
        title: "ICO Telegram Marketing",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/ICO STO IDO/ICO Influencer Marketing.svg",
        href: "/ico/influencer-marketing",
        title: "ICO Influencer Marketing",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/ICO STO IDO/IEO Development.svg",
        href: "/ico/ieo-development",
        title: "IEO Development",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/ICO STO IDO/ETO Development.svg",
        href: "/ico/eto-development",
        title: "ETO Development",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/ICO STO IDO/Crowdfunding Platform.svg",
        href: "/ico/crowdfunding-platform",
        title: "Crowdfunding Platform",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/ICO STO IDO/ICO Youtube Influencer.svg",
        href: "/ico/youtube-influencer",
        title: "ICO Youtube Influencer",
    },
];

export const sto1 = [
    {
        id: 0,
        title: "2. STO",
        // href: "/",
        textClr: "text-[#410ead]",
    },
    {
        id: 1,
        image: "/assets/Header/Blockchain/ICO STO IDO/STO Development.svg",
        href: "/sto/development",
        title: "STO Development",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/ICO STO IDO/STO Marketing.svg",
        href: "/sto/marketing",
        title: "STO Marketing",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/ICO STO IDO/STO Smart Contracts.svg",
        href: "/sto/smart-contract-development",
        title: "STO Smart Contracts",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/ICO STO IDO/Real Estate Tokenization.svg",
        href: "/sto/real-estate-tokenization",
        title: "STO Real Estate Tokenization",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/ICO STO IDO/Asset Tokenization.svg",
        href: "/sto/asset-tokenization",
        title: "STO Asset Tokenization",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/ICO STO IDO/Art Tokenization.svg",
        href: "/sto/art-tokenization",
        title: "STO Art Tokenization",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/ICO STO IDO/Tokenization Platform.svg",
        href: "/sto/tokenization-platform-development",
        title: "STO Tokenization Platform",
    },
];

export const ido1 = [
    {
        id: 0,
        // href: "",
        title: "3. IDO/Launchpad",
        textClr: "text-[#410ead]",
    },
    {
        id: 1,
        image: "/assets/Header/Blockchain/ICO STO IDO/IDO Development.svg",
        href: "/ido/development",
        title: "IDO Development",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/ICO STO IDO/IDO Marketing.svg",
        href: "/ido/marketing",
        title: "IDO Marketing",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/ICO STO IDO/White Label IDO Launchpad.svg",
        href: "/ido/white-label-ido-launchpad",
        title: "White Label IDO Launchpad",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/ICO STO IDO/IDO Token Launchpad.svg",
        href: "/ido/ido-token-launchpad-development",
        title: "IDO Token Launchpad",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/ICO STO IDO/IDO Launchpad On BSC.svg",
        href: "/ido/ido-launchpad-development-on-bsc",
        title: "IDO Launchpad On BSC",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/ICO STO IDO/IDO Launchpad On Ethereum.svg",
        href: "/ido/ido-launchpad-development-on-ethereum",
        title: "IDO Launchpad On Ethereum",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/ICO STO IDO/IDO Launchpad In Multichain.svg",
        href: "/ido/ido-launchpad-development-on-multichain",
        title: "IDO Launchpad In Multichain",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/ICO STO IDO/IDO Launchpad On Polygon.svg",
        href: "/ido/ido-launchpad-development-on-polygon",
        title: "IDO Launchpad On Polygon",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/ICO STO IDO/IGO Launchpad Development.svg",
        href: "/ido/igo-launchpad-development",
        title: "IGO Launchpad Development",
    },
];

export const crypto1 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Development.svg",
        href: "/crypto/cryptocurrency-development",
        title: "Cryptocurrency Development",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/Crypto/Stable Coin Development.svg",
        href: "/crypto/stable-coin-development",
        title: "Stable Coin Development",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/Crypto/White label Crypto Exchange.svg",
        href: "/crypto/white-label-crypto-exchange",
        title: "White Label Crypto Exchange",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Exchange Development.svg",
        href: "/crypto/cryptocurrency-exchange-development",
        title: "Cryptocurrency Exchange Development",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/Crypto/Decentralized Exchange Development.svg",
        href: "/crypto/decentralized-exchange-development",
        title: "Decentralized Exchange Development",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/Crypto/P2P Exchange Development.svg",
        href: "/crypto/p2p-exchange-development",
        title: "Crypto P2P Exchange Development",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/Crypto/Wallet Development.svg",
        href: "/crypto/crypto-wallet-development",
        title: "Crypto Wallet Development",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/Crypto/Crypto-Payment Development.svg",
        href: "/crypto/crypto-payment-development",
        title: "Crypto-Payment Development",
    },
    {
        id: 9,
        image: "/assets/Header/Blockchain/Crypto/Exchange Listing Services.svg",
        href: "/crypto/crypto-exchange-listing-services",
        title: "Crypto Exchange Listing Services",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Exchange Like Wazirx.svg",
        href: "/crypto/cryptocurrency-exchange-like-wazirx",
        title: "Cryptocurrency Exchange Like Wazirx",
    },
    {
        id: 11,
        image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Exchange Like Binance.svg",
        href: "/crypto/cryptocurrency-exchange-like-binance",
        title: "Cryptocurrency Exchange Like Binance",
    },
];

export const crypto2 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Exchange Like Coinbase.svg",
        href: "/crypto/cryptocurrency-exchange-like-coinbase",
        title: "Cryptocurrency Exchange Like Coinbase",
    },
];

// export const crypto3 = [
//   {
//     id: 1,
//     image: "/assets/Header/Blockchain/Crypto/Cryptocurrency Exchange Marketing.svg",
//     href: "/crypto/cryptocurrency-exchange-like-binance",
//     title: "Cryptocurrency Exchange Marketing",
//   },
// ];

export const blockchain1 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain Development.svg",
        href: "/blockchain/blockchain-development-services",
        title: "Blockchain Development Services",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/Blockchain Sub/White Label Blockchain Solutions.svg",
        href: "/blockchain/white-label-blockchain-development-services",
        title: "White Label Blockchain Solutions",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/Blockchain Sub/Custom Blockchain On Avalanche.svg",
        href: "/blockchain/custom-blockchain-development-on-avalanche",
        title: "Custom Blockchain On Avalanche",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/Blockchain Sub/Custom Blockchain On Polygon.svg",
        href: "/blockchain/custom-blockchain-development-on-polygon",
        title: "Custom Blockchain On Polygon",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/Blockchain Sub/Solana Blockchain Development.svg",
        href: "/blockchain/solana-blockchain-development",
        title: "Solana Blockchain Development",
    },
    {
        id: 6,
        image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain  Web3.0 Consulting.svg",
        href: "/blockchain/blockchain-and-web3-consulting",
        title: "Blockchain and Web 3.0 Consulting",
    },
    {
        id: 7,
        image: "/assets/Header/Blockchain/Blockchain Sub/Proof Of Reserve.svg",
        href: "/blockchain/blockchain-proof-of-reserve",
        title: "Blockchain Proof Of Reserve",
    },
    {
        id: 8,
        image: "/assets/Header/Blockchain/Blockchain Sub/Carbon Credits Development.svg",
        href: "/blockchain/carbon-credits-development",
        title: "Carbon Credits Development",
    },
    ,
    {
        id: 9,
        image: "/assets/Header/Blockchain/Blockchain Sub/DApps Development.svg",
        href: "/blockchain/blockchain-dapps-development",
        title: "Blockchain dApps Development",
    },
    {
        id: 10,
        image: "/assets/Header/Blockchain/Blockchain Sub/P2P Lending Software.svg",
        href: "/blockchain/blockchain-p2p-lending-software",
        title: "Blockchain P2P Lending Software",
    },
    {
        id: 11,
        image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain for Finance.svg",
        href: "/blockchain/blockchain-for-finance",
        title: "Blockchain For Finance",
    },
];

export const blockchain2 = [
    {
        id: 1,
        image: "/assets/Header/Blockchain/Blockchain Sub/Protocol Implementation.svg",
        href: "/blockchain/blockchain-protocol-implementation",
        title: "Blockchain Protocol Implementation",
    },
    {
        id: 2,
        image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain for Pharma Industry.svg",
        href: "/blockchain/blockchain-for-pharma-industry",
        title: "Blockchain For Pharma Industry",
    },
    {
        id: 3,
        image: "/assets/Header/Blockchain/Blockchain Sub/EVM Compatible Blockchain and Service.svg",
        href: "/blockchain/evm-compatible-blockchain-and-service",
        title: "EVM Compatible Blockchain and Service",
    },
    {
        id: 4,
        image: "/assets/Header/Blockchain/Blockchain Sub/Smart Contract Development.svg",
        href: "/blockchain/smart-contract-development",
        title: "Smart Contract Development",
    },
    {
        id: 5,
        image: "/assets/Header/Blockchain/Blockchain Sub/Quantum-Blockchain-Development.svg",
        href: "/blockchain/quantum-blockchain-development-services",
        title: "Quantum Blockchain Development",
    },
];

// export const blockchain3 = [
//   {
//     id: 1,
//     image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain for Finance.svg",
//     href: "",
//     title: "Blockchain for Finance",
//   },
// ];
