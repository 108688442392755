export const gamesMainItemMobile = [
    {
        id: 1,
        title: "Game Development",
        gameDevelopment: [
            {
                id: 1,
                image: "/assets/Header/Blockchain/Blockchain Sub/Blockchain Game Development.svg",
                href: "/games/blockchain",
                title: "Blockchain Game Development",
            },
            {
                id: 2,
                image: "/assets/Header/Games/Game Development/Play-To-Earn Game Development.svg",
                title: "Play-To-Earn Game Development",
                href: "/games/play-to-earn",
            },
            {
                id: 3,
                image: "/assets/Header/Games/Game Development/NFT Game Development.svg",
                title: "NFT Game Development",
                href: "/games/nft",
            },
            {
                id: 4,
                image: "/assets/Header/Games/Game Development/Web3 Game Development.svg",
                title: "Web3 Game Development",
                href: "/games/web3",
            },
            {
                id: 5,
                image: "/assets/Header/Games/Game Development/Metaverse Game Development.svg",
                title: "Metaverse Game Development",
                href: "/games/metaverse",
            },
            {
                id: 6,
                image: "/assets/Header/Games/Game Development/Ai Game Development.svg",
                title: "AI Game Development",
                href: "/games/ai",
            },
            {
                id: 7,
                image: "/assets/Header/Games/Game Development/Augmented reality Game.svg",
                title: "Augmented Reality Game Development",
                href: "/games/augmented-reality",
            },
            {
                id: 8,
                image: "/assets/Header/Games/Game Development/Virtual Reality.svg",
                title: "Virtual Reality Game Development",
                href: "/games/virtual-reality",
            },
            {
                id: 9,
                image: "/assets/Header/Games/Game Development/3D Game Development.svg",
                title: "3D Game Development",
                href: "/games/3d",
            },
            {
                id: 10,
                image: "/assets/Header/Games/Game Development/Battle Royale Game Development.svg",
                title: "Battle Royale Game Development",
                href: "/games/battle-royale",
            },
            {
                id: 11,
                image: "/assets/Header/Games/Game Development/Hyper Casual Game Development.svg",
                title: "Hyper Casual Game Development",
                href: "/games/hyper-casual",
            },
            {
                id: 12,
                image: "/assets/Header/Games/Game Development/Unity Development.svg",
                title: "Unity Development",
                href: "/games/unity",
            },
            {
                id: 13,
                image: "/assets/Header/Games/Game Development/Unreal Development.svg",
                title: "Unreal Development",
                href: "/games/unreal",
            },
            {
                id: 14,
                image: "/assets/Header/Games/Game Development/Mobile Game Development.svg",
                title: "Mobile Game Development",
                href: "/games/mobile",
            },
            {
                id: 15,
                image: "/assets/Header/Games/Game Development/Game Outsourcing.svg",
                title: "Game Development Outsourcing",
                href: "/games/game-development-outsourcing",
            },
            {
                id: 16,
                image: "/assets/Header/Games/Game Development/Mmorpg Game Development.svg",
                title: "Mmorpg Game Development",
                href: "/games/mmorpg",
            },
            {
                id: 17,
                image: "/assets/Header/Games/Game Development/Buildbox Game Development.svg",
                title: "Buildbox Game Development",
                href: "/games/buildbox",
            },
            {
                id: 18,
                image: "/assets/Header/Games/Game Development/Gamification Services.svg",
                title: "Gamification Services",
                href: "/games/gamification-services",
            },
            {
                id: 19,
                image: "/assets/Header/Games/Game Development/CPlusPlusGameDevelopment.svg",
                title: "C++ Game Development",
                href: "/games/c",
            },
            {
                id: 20,
                image: "/assets/Header/Games/Game Development/NFT Desing Company.svg",
                title: "NFT Design Services",
                href: "/games/nft-design-services",
            },
            {
                id: 21,
                image: "/assets/Header/Games/Game Development/Educational Game.svg",
                title: "Educational Game Development",
                href: "/games/education",
            },
            {
                id: 22,
                image: "/assets/Header/Games/Game Development/HTML5 Game Development.svg",
                title: "HTML5 Game Development",
                href: "/games/html5",
            },
            {
                id: 23,
                image: "/assets/Header/Games/Game Development/Social Network Game.svg",
                title: "Social Network Game Development",
                href: "/games/social-network",
            },
            {
                id: 24,
                image: "/assets/Header/Games/Game Development/2D Game Development.svg",
                title: "2D Game Development",
                href: "/games/2d",
            },
            {
                id: 25,
                image: "/assets/Header/Games/Game Development/Console Game Development.svg",
                title: "Console Game Development",
                href: "/games/console",
            },
            {
                id: 26,
                image: "/assets/Header/Games/Game Development/Cross-Platform.svg",
                title: "Cross-Platform Game Development",
                href: "/games/cross-platform",
            },
            {
                id: 27,
                image: "/assets/Header/Games/Game Development/Game Prototyping.svg",
                title: "Game Prototyping",
                href: "/games/game-prototyping",
            },
            {
                id: 28,
                image: "/assets/Header/Games/Game Development/Game LiveOps Services.svg",
                title: "Game LiveOps Services",
                href: "/games/game-liveops",
            },
            {
                id: 29,
                image: "/assets/Header/Games/Game Development/Co-Development.svg",
                title: "Co Development",
                href: "/games/co-development-game-services",
            },
            {
                id: 30,
                image: "/assets/Header/Games/Game Development/Android Game Development.svg",
                title: "Android Game Development",
                href: "/games/android",
            },
            {
                id: 31,
                image: "/assets/Header/Games/Game Development/iOS Game Development.svg",
                title: "iOS Game Development",
                href: "/games/ios",
            },
            {
                id: 32,
                image: "/assets/Header/Games/Game Development/Game Testing.svg",
                title: "Game Testing",
                href: "/games/game-testing",
            },
            {
                id: 33,
                image: "/assets/Header/Games/Game Development/Game Porting.svg",
                title: "Game Porting",
                href: "/games/game-porting",
            },
            {
                id: 34,
                image: "/assets/Header/Games/Game Development/PC Game Development.svg",
                title: "PC Game Development",
                href: "/games/pc",
            },
        ],
    },
    {
        id: 2,
        title: "Game Art",
        gameArt: [
            {
                id: 1,
                image: "/assets/Header/Games/Game Art/Game Economy Design.svg",
                title: "Game Economy Design",
                href: "/game-art/game-economy-design",
            },
            {
                id: 2,
                image: "/assets/Header/Games/Game Art/AAA.svg",
                title: "AAA",
                href: "/game-art/aaa",
            },
            {
                id: 3,
                image: "/assets/Header/Games/Game Art/3D Environment Design.svg",
                title: "3D Environment Design",
                href: "/game-art/3d-environment-design",
            },
            {
                id: 4,
                image: "/assets/Header/Games/Game Art/3D Game Modeling.svg",
                title: "3D Game Modeling",
                href: "/game-art/3d-game-modeling",
            },
            {
                id: 5,
                image: "/assets/Header/Games/Game Art/3D Sculpting.svg",
                title: "3D Sculpting",
                href: "/game-art/3d-sculpting",
            },
            {
                id: 6,
                image: "/assets/Header/Games/Game Art/Character Design.svg",
                title: "Character Design",
                href: "/game-art/character-design",
            },
            {
                id: 7,
                image: "/assets/Header/Games/Game Art/3D Character Design.svg",
                title: "3D Character Design",
                href: "/game-art/3d-character-design",
            },
            {
                id: 8,
                image: "/assets/Header/Games/Game Art/Environment Design.svg",
                title: "Environment Design",
                href: "/game-art/environment-design",
            },
            {
                id: 9,
                image: "/assets/Header/Games/Game Art/2D Environment Design.svg",
                title: "2D Environment Design",
                href: "/game-art/2d-environment-design",
            },
            {
                id: 10,
                image: "/assets/Header/Games/Game Art/2D Characters Design.svg",
                title: "2D Characters Design",
                href: "/game-art/2d-characters-design",
            },
            {
                id: 11,
                image: "/assets/Header/Games/Game Art/Concept Art.svg",
                title: "Concept Art",
                href: "/game-art/concept-art",
            },
            {
                id: 12,
                image: "/assets/Header/Games/Game Art/UIUX Design.svg",
                title: "UI/UX Design",
                href: "/game-art/ui-ux-design",
            },
            {
                id: 13,
                image: "/assets/Header/Games/Game Art/Game Level Design.svg",
                title: "Game Level Design",
                href: "/game-art/game-level-design",
            },
            {
                id: 14,
                image: "/assets/Header/Games/Game Art/Illustration Services.svg",
                title: "Illustration Services",
                href: "/game-art/illustration-services",
            },
            {
                id: 15,
                image: "/assets/Header/Games/Game Art/Slots Games Art.svg",
                title: "Slots Games Art",
                href: "/game-art/slots-games-art",
            },
            {
                id: 16,
                image: "/assets/Header/Games/Game Art/3D Art.svg",
                title: "3D Art",
                href: "/game-art/3d-art",
            },
            {
                id: 17,
                image: "/assets/Header/Games/Game Art/Hidden Objects.svg",
                title: "Hidden Objects",
                href: "/game-art/hidden-objects",
            },
            {
                id: 18,
                image: "/assets/Header/Games/Game Art/Casual Art.svg",
                title: "Casual Art",
                href: "/game-art/casual-art",
            },
            {
                id: 19,
                image: "/assets/Header/Games/Game Art/2D Art.svg",
                title: "2D Art",
                href: "/game-art/2d-art",
            },
        ],
    },
    {
        id: 3,
        title: "Animation",
        animation: [
            {
                id: 1,
                image: "/assets/Header/Games/Animation/2D Animation.svg",
                title: "2D Animation",
                href: "/game-animation/2d",
            },
            {
                id: 2,
                image: "/assets/Header/Games/Animation/Pre-Production animation services.svg",
                title: "Pre-Production Animation Services",
                href: "/game-animation/pre-production",
            },
            {
                id: 3,
                image: "/assets/Header/Games/Animation/Asset Production animation services.svg",
                title: "Asset Production Animation Services",
                href: "/game-animation/asset-production",
            },
            {
                id: 4,
                image: "/assets/Header/Games/Animation/3D Animation.svg",
                title: "3D Animation",
                href: "/game-animation/3d",
            },
            {
                id: 5,
                image: "/assets/Header/Games/Animation/Integrated Level Art Production animation services.svg",
                title: "Integrated Level Art Production Animation Services",
                href: "/game-animation/integrated-level-art-production",
            },
            {
                id: 6,
                image: "/assets/Header/Games/Animation/Animation and Visual Effects.svg",
                title: "Animation and Visual Effects",
                href: "/game-animation/animation-and-visual-effects",
            },
            {
                id: 7,
                image: "/assets/Header/Games/Animation/Motion Graphic Animation.svg",
                title: "Motion Graphic Animation",
                href: "/game-animation/motion-graphic",
            },
        ],
    },
    // {
    //     id: 4,
    //     title: "Outstaffing",
    //     outstaffing: [
    //         {
    //             id: 1,
    //             image: "/assets/Header/Games/Outstaffing/Hire Game Developers.svg",
    //             title: "Hire Game Developers",
    //             href: "/game-resources/hire-game-developers",
    //         },
    //         {
    //             id: 2,
    //             image: "/assets/Header/Games/Outstaffing/Hire Unity Developers.svg",
    //             title: "Hire Unity Developers",
    //             href: "/game-resources/hire-unity-developers",
    //         },
    //         {
    //             id: 3,
    //             image: "/assets/Header/Games/Outstaffing/Hire Unreal Developers.svg",
    //             title: "Hire Unreal Developers",
    //             href: "/game-resources/hire-unreal-developers",
    //         },
    //         {
    //             id: 4,
    //             image: "/assets/Header/Games/Outstaffing/Hire 3D Artists.svg",
    //             title: "Hire 3D Artists",
    //             href: "/game-resources/hire-3d-artists",
    //         },
    //         {
    //             id: 5,
    //             image: "/assets/Header/Games/Outstaffing/Hire 2D Artists.svg",
    //             title: "Hire 2D Artists",
    //             href: "/game-resources/hire-2d-artists",
    //         },
    //         {
    //             id: 6,
    //             image: "/assets/Header/Games/Outstaffing/Hire Illustrators.svg",
    //             title: "Hire Illustrators",
    //             href: "/game-resources/hire-illustrators",
    //         },
    //         {
    //             id: 7,
    //             image: "/assets/Header/Games/Outstaffing/Hire 3d Modelers.svg",
    //             title: "Hire 3d Modelers",
    //             href: "/game-resources/hire-3d-modelers",
    //         },
    //         {
    //             id: 8,
    //             image: "/assets/Header/Games/Outstaffing/Hire Game Designer.svg",
    //             title: "Hire Game Designers",
    //             href: "/game-resources/hire-game-designers",
    //         },
    //         {
    //             id: 9,
    //             image: "/assets/Header/Games/Outstaffing/Hire Character Designers.svg",
    //             title: "Hire Character Designers",
    //             href: "/game-resources/hire-character-designers",
    //         },
    //         {
    //             id: 10,
    //             image: "/assets/Header/Games/Outstaffing/Hire Concept Artists.svg",
    //             title: "Hire Concept Artists",
    //             href: "/game-resources/hire-concept-artists",
    //         },
    //         {
    //             id: 11,
    //             image: "/assets/Header/Games/Outstaffing/Hire 2D Animators.svg",
    //             title: "Hire 2D Animators",
    //             href: "/game-resources/hire-2d-animators",
    //         },
    //         {
    //             id: 12,
    //             image: "/assets/Header/Games/Outstaffing/Hire 3D Animators.svg",
    //             title: "Hire 3D Animators",
    //             href: "/game-resources/hire-3d-animators",
    //         },
    //     ],
    // },
];
